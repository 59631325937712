import React, { useEffect, useState, useContext } from 'react';
import {
  TextInput,
  SelectBox,
  Check,
  DatePickerBox,
  DateTimePickerBox,
  RadioGroupBox,
  FileUploader,
  ToastEditor,
  FileMultipleUploader,
  TextViewer,
  TableBox,
} from '../../components';
import { usePagination, useFilter, useForm } from '../../hooks';
import Util from '../../util';
import API from '../../api';
import { SnackbarContext, DialogContext } from '../../contexts';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';

const GetLabel = (type, value) => {
  if (type === 'region') {
    switch (value) {
      case 'all': {
        return '올인원';
      }
      case 'domestic': {
        return '한국';
      }
      case 'usa': {
        return '미국';
      }
      case 'vnm': {
        return '베트남';
      }
      case 'jpn': {
        return '일본';
      }
      case 'ida': {
        return '인도네시아';
      }
      default: {
        return value;
      }
    }
  }

  if (type === 'price_id') {
    switch (value) {
      case 'ALL_THREE_MONTH': {
        return '3개월';
      }
      case 'ALL_ONE_YEAR': {
        return '1년';
      }
      case 'ALL_TWO_YEAR': {
        return '2년';
      }
      case 'ALL_FIVE_YEAR': {
        return '5년';
      }
      case 'THREE_MONTH': {
        return '3개월';
      }
      case 'ONE_YEAR': {
        return '1년';
      }
      case 'TWO_YEAR': {
        return '2년';
      }
      case 'FIVE_YEAR': {
        return '5년';
      }
      case 'RECURRING_ALL_ONE_YEAR': {
        return '구독 1년';
      }
      case 'RECURRING_ONE_YEAR': {
        return '구독 1년';
      }
      default: {
        return value;
      }
    }
  }
};

export const MemberDetailPage = (props) => {
  const params = useParams();
  const { slug } = params;
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { dialog, updateDialog } = useContext(DialogContext);
  const [data, setData] = useState(null);
  const [membershipList, setMembershipList] = useState([]);
  const [paymentList, setPaymentList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [paymentHeader, setPaymentHeader] = useState([
    {
      render: (data) => {
        let text = '';

        if (data.rewardDate) {
          text = text + '(' + Util.Form.getDayByUnixTime(data.rewardDate) + '일)';
        } else {
          text = GetLabel('region', data.serviceRegion) + ' ' + GetLabel('price_id', data.type);
        }

        return text;
      },
      text: '유형',
      align: 'center',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'method',
      align: 'center',
      text: '결제 방법',
      style: {
        minWidth: 100,
      },
      render: (value) => {
        if (value === 'card') {
          return '카드';
        } else {
          return '포인트';
        }
      },
    },
    {
      key: 'amount',
      align: 'center',
      text: '가격',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'refereePoint',
      align: 'center',
      text: '추천인 point',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'referrerPoint',
      align: 'center',
      text: '본인 point',
      style: {
        minWidth: 100,
      },
    },
    {
      key: 'status',
      align: 'center',
      text: '상태',
    },
    {
      render: (data) => {
        if (data.recommendCode) {
          return data.recommendCode;
        } else if (data.recommendId) {
          return data.recommendId;
        } else {
          return '';
        }
      },
      align: 'center',
      text: '추천인',
    },
    {
      key: 'statusUpdatedAt',
      align: 'center',
      text: '결제일',
      render: (value) => {
        return Util.Form.getYYYYMMDDByUnix(value);
      },
    },
  ]);

  const [pointList, setPointList] = useState([
    {
      name: 'name',
      description: 'description',
    },
    {
      name: 'name2',
      description: 'description2',
    },
  ]);
  const [pointHeader, setPointHeader] = useState([
    {
      render: (data) => {
        switch (data) {
          case 'PaymentEarning': {
            return '적립';
          }
          case 'PaymentPoints': {
            return '사용';
          }
          case 'TransferFrom': {
            return '전송';
          }
          case 'TransferTo': {
            return '수령';
          }
          case 'Calculate': {
            return '정산';
          }
          default: {
            return 'none';
          }
        }
      },
      key: 'type',
      text: '유형',
      align: 'center',
      style: {
        minWidth: 100,
      },
    },

    {
      align: 'center',
      text: '가격',
      style: {
        minWidth: 100,
      },
      render: (data) => {
        switch (data.type) {
          case 'PaymentEarning': {
            return <span style={{ color: 'blue' }}>{Util.Form.formatNumber(data.amount) + ' (+)'}</span>;
          }
          case 'PaymentPoints': {
            return <span style={{ color: 'red' }}>{Util.Form.formatNumber(data.amount) + ' (-)'}</span>;
          }
          case 'TransferFrom': {
            return <span style={{ color: 'red' }}>{Util.Form.formatNumber(data.amount) + ' (-)'}</span>;
          }
          case 'TransferTo': {
            return <span style={{ color: 'blue' }}>{Util.Form.formatNumber(data.amount) + ' (+)'}</span>;
          }
          case 'Calculate': {
            return <span style={{ color: 'red' }}>{Util.Form.formatNumber(data.amount) + ' (-)'}</span>;
          }
          default: {
            return 'none';
          }
        }
      },
    },
    {
      key: 'from',
      align: 'center',
      text: 'From',
    },
    {
      key: 'to',
      align: 'center',
      text: 'To',
    },
    {
      key: 'createdAt',
      align: 'center',
      text: '결제일',
      render: (value) => {
        return Util.Form.getYYYYMMDDByUnix(value);
      },
    },
  ]);

  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      modifyType: 'ADD',
      regionType: 'all',
      period: 'ONE_YEAR',
      days: 0,
    },
    {
      modifyType: {
        key: 'modifyType',
        label: '구분',
        fullWidth: true,
        menus: [
          {
            value: 'ADD',
            label: '추가',
          },
          {
            value: 'DELETE',
            label: '제거',
          },
        ],
      },
      regionType: {
        key: 'regionType',
        label: '타입',
        fullWidth: true,
        menus: [],
      },
      period: {
        key: 'period',
        label: '기간',
        fullWidth: true,
        menus: [
          {
            value: 'ONE_YEAR',
            label: '1년',
          },
          {
            value: 'TWO_YEAR',
            label: '2년',
          },
          {
            value: 'DAYS',
            label: '일간',
          },
        ],
      },
      days: {
        key: 'days',
        label: '일간',
        fullWidth: true,
      },
    },
  );

  const [open, setOpen] = useState(false);

  const [subscriptionList, setSubscriptionList] = useState([]);

  console.log('subscriptionList', subscriptionList);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (slug) {
      requesetNewData();
    }
  }, [slug]);

  const requesetNewData = () => {
    API.Member.GetDetail(slug).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetDetail ', result);
        setData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetMembership(slug).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetMembership ', result);
        setMembershipList(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetPayment(slug).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPayment ', result);
        setPaymentList(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetPoint(slug).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPayment ', result);
        setPointList(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetRegion().then(
      (res) => {
        let result = res.data;
        console.log('Member.GetRegion ', result);

        let newMenus = [];
        for (let i in result) {
          let target = result[i];
          if (target.isOpen) {
            newMenus.push({
              value: target.region,
              label: target.title,
            });
          }
        }
        let newFormMeta = _.clone(formMeta);
        newFormMeta.regionType.menus = newMenus;
        setFormMeta(newFormMeta);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );

    API.Member.GetPaymentSubscription(slug).then(
      (res) => {
        let result = res.data;
        console.log('Member.GetPaymentSubscription ', result);
        setSubscriptionList(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestPutMembership = () => {
    console.log('requestPutMembership');
    let body = Util.Form.formToBody(formData, formMeta);
    console.log('requestPutMembership body', body);

    API.Member.PutMembership(slug, body).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        updateSnackbar({
          type: 'success',
          open: true,
          message: '멤버쉽 변경 성공',
        });
        requesetNewData();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const updateCancelAtPeriodEnd = (array, no, newCancelAtPeriodEnd) => {
    // 주어진 배열에서 'no' 값에 해당하는 항목을 찾아 'cancelAtPeriodEnd' 값을 업데이트
    const updatedArray = array.map((item) => {
      if (item.no === no) {
        return { ...item, cancelAtPeriodEnd: newCancelAtPeriodEnd };
      }
      return item;
    });
    return updatedArray;
  };

  const requestPutSubscriptionModify = (item) => {
    console.log('requestPutSubscriptionModify', item);

    let body = {
      modifyType: item.cancelAtPeriodEnd ? 'REACTIVE' : 'CANCEL',
    };
    API.Member.PutPaymentSubscriptionModify(slug, item.no, body).then(
      (res) => {
        let result = res.data;
        console.log('Member.PutPaymentSubscriptionModify', result);
        let newSubscriptionList = updateCancelAtPeriodEnd(subscriptionList, item.no, result.cancelAtPeriodEnd);
        setSubscriptionList(newSubscriptionList);
        setTimeout(() => {
          requesetNewData();
        }, 2000);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  if (!data) {
    return null;
  }

  return (
    <article className='basic-page'>
      <section className='content-header__container'>
        <header>
          <h1>고객 상세</h1>
        </header>
        <div className='button__box'>
          <Button
            variant='contained'
            style={{ marginRight: 16 }}
            onClick={() => {
              handleOpen();
            }}
          >
            멤버쉽 변경
          </Button>
        </div>
      </section>
      <section className='form__container'>
        <header>
          <h3>기본 정보</h3>
        </header>
        <div className='flex'>
          <TextViewer
            value={data.id}
            meta={{
              key: 'createdAt',
              label: '아이디(이메일)',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.name}
            meta={{
              key: 'name',
              label: '이름',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.nick}
            meta={{
              key: 'nick',
              label: '닉네임',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.phone}
            meta={{
              key: 'phone',
              label: '전화번호',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.point}
            meta={{
              key: 'point',
              label: '포인트',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.lockPoint}
            meta={{
              key: 'lockPoint',
              label: '정산 요청 포인트',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.calculatePoint}
            meta={{
              key: 'calculatePoint',
              label: '정산 완료 포인트',
              render: (value) => {
                return value;
              },
            }}
          ></TextViewer>
          {/* <TextViewer
            value={data.vipDate}
            meta={{
              key: "vipDate",
              label: "국내 멤버쉽 만료일",
              render: (value) => {
                return Util.Form.getYYYYMMDDHHMMssByUnix(value);
              },
            }}
          ></TextViewer>
          <TextViewer
            value={data.vipDateUsa}
            meta={{
              key: "vipDateUsa",
              label: "해외 멤버쉽 만료일",
              render: (value) => {
                return Util.Form.getYYYYMMDDHHMMssByUnix(value);
              },
            }}
          ></TextViewer> */}
          {/* <TextViewer
            value={data.vipDateAll}
            meta={{
              key: "vipDateAll",
              label: "올인원 멤버쉽 만료일",
              render: (value) => {
                return Util.Form.getYYYYMMDDHHMMssByUnix(value);
              },
            }}
          ></TextViewer> */}
        </div>
      </section>
      <section className='form__container' style={{ marginTop: 40 }}>
        <header style={{ marginBottom: 24 }}>
          <h1>멤버쉽 정보</h1>
        </header>

        <div className='flex'>
          {membershipList.map((item, index) => {
            return (
              <TextViewer
                value={item.date}
                meta={{
                  key: item.region,
                  label: item.title,
                  render: (value) => {
                    return Util.Form.getYYYYMMDDHHMMssByUnix(value);
                  },
                }}
              ></TextViewer>
            );
          })}
        </div>
      </section>
      <section className='form__container' style={{ marginTop: 40 }}>
        <header style={{ marginBottom: 24 }}>
          <h1>구독 정보</h1>
        </header>

        <div className='subscription__container'>
          {subscriptionList.map((item, index) => {
            return (
              <div className='subscription__item'>
                <TextViewer
                  value={`${Util.Form.getYYYYMMDDHHMMssByUnix(item.periodStart)} ~ ${Util.Form.getYYYYMMDDHHMMssByUnix(
                    item.periodEnd,
                  )}`}
                  meta={{
                    key: item.requestId,
                    label: GetLabel('region', item.serviceRegion) + ' ' + GetLabel('price_id', item.requestId),
                    render: (value) => {
                      return value;
                    },
                  }}
                ></TextViewer>
                <TextViewer
                  value={item.status}
                  meta={{
                    key: item.status,
                    label: '상태',
                    render: (value) => {
                      return value;
                    },
                  }}
                ></TextViewer>
                <Button
                  disabled={item.status === 'canceled'}
                  variant='contained'
                  fullWidth
                  onClick={() => {
                    requestPutSubscriptionModify(item);
                  }}
                >
                  {item.cancelAtPeriodEnd ? '재구독 요청' : '구독 취소 요청'}
                </Button>
              </div>
            );
          })}
          {subscriptionList.length === 0 && <span>구독 내역이 없습니다.</span>}
        </div>
      </section>
      <section className='table__container' style={{ marginTop: 40 }}>
        <header style={{ marginBottom: 24 }}>
          <h1>결제 내역</h1>
        </header>
        <TableBox data={paymentList} header={paymentHeader} rowClick={() => {}} callback={() => {}}></TableBox>
      </section>

      <section className='table__container' style={{ marginTop: 40 }}>
        <header style={{ marginBottom: 24 }}>
          <h1>포인트 내역</h1>
        </header>
        <TableBox data={pointList} header={pointHeader} rowClick={() => {}} callback={() => {}}></TableBox>
      </section>
      <Modal open={open} onClose={handleClose}>
        <article className='basic-modal'>
          <section className='header__box'>
            <h3>멤버쉽 변경</h3>
            <div className='button__box'>
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon></CloseIcon>
              </IconButton>
            </div>
          </section>
          <section className='content__box'>
            <div className='input-list__box'>
              <SelectBox value={formData.modifyType} onChange={formChange} meta={formMeta.modifyType}></SelectBox>
              <SelectBox value={formData.regionType} onChange={formChange} meta={formMeta.regionType}></SelectBox>
              <SelectBox value={formData.period} onChange={formChange} meta={formMeta.period}></SelectBox>
              {formData.period === 'DAYS' && (
                <TextInput value={formData.days} onChange={formChange} meta={formMeta.days}></TextInput>
              )}
            </div>
            <section className='submit__box'>
              <Button variant='contained' fullWidth onClick={requestPutMembership}>
                멤버쉽 변경 요청
              </Button>
            </section>
          </section>
        </article>
      </Modal>
    </article>
  );
};
