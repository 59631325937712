import { BASE_URL } from '../Config';
import AxiosInstance from '../AxiosInstance';
import Util from '../../util';

const PATH = 'member/';

const Get = (params) => {
  const url = BASE_URL + PATH + Util.Form.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (slug) => {
  const url = BASE_URL + PATH + slug;
  return AxiosInstance.get(url);
};

const PutMembership = (slug, body) => {
  const url = BASE_URL + PATH + slug + '/membership';
  return AxiosInstance.put(url, body);
};

const GetPayment = (slug) => {
  const url = BASE_URL + PATH + slug + '/payment';
  return AxiosInstance.get(url);
};

const GetPoint = (slug) => {
  const url = BASE_URL + PATH + slug + '/point';
  return AxiosInstance.get(url);
};

const GetMembership = (slug) => {
  const url = BASE_URL + PATH + slug + '/membership';
  return AxiosInstance.get(url);
};

const GetRegion = () => {
  const url = BASE_URL + PATH + 'membership/region';
  return AxiosInstance.get(url);
};

const GetPaymentSubscription = (slug) => {
  const url = BASE_URL + PATH + slug + '/payment/subscription';
  return AxiosInstance.get(url);
};

const PutPaymentSubscriptionModify = (slug, no, body) => {
  const url = BASE_URL + PATH + slug + '/payment/subscription/' + no;
  return AxiosInstance.put(url, body);
};

const Member = {
  Get: Get,
  GetDetail: GetDetail,
  PutMembership: PutMembership,
  GetPayment: GetPayment,
  GetPoint: GetPoint,
  GetMembership: GetMembership,
  GetRegion: GetRegion,
  GetPaymentSubscription: GetPaymentSubscription,
  PutPaymentSubscriptionModify: PutPaymentSubscriptionModify,
};

export default Member;
